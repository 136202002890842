/**
 * @file
 *
 * this file contains various app wide constants and enums
 */

import { nanoid } from 'nanoid';

export const SYSTEMS = {
  SF_EC: {
    KEY: 'SF_EC',
    LABEL: 'SuccessFactors Employee Central',
  },
  ODATA_SERVICE: {
    KEY: 'ODATA_SERVICE',
    LABEL: 'OData Service',
  },
  SF_EC_SHARED: {
    KEY: 'SF_EC_SHARED',
    LABEL: 'Demo SuccessFactors Employee Central',
  },
  ODATA_SERVICE_SHARED: {
    KEY: 'ODATA_SERVICE_SHARED',
    LABEL: 'Demo OData Service',
  },
};

export const SYSTEM_ENVIRONMENTS = {
  DEVELOPMENT: {
    KEY: 'development',
    LABEL: 'Development',
  },
  PRODUCTION: {
    KEY: 'production',
    LABEL: 'Production',
  },
  SANDBOX: {
    KEY: 'sandbox',
    LABEL: 'Sandbox',
  },
  QUALITY: {
    KEY: 'quality',
    LABEL: 'Quality',
  },
};

export const SYSTEM_CONFIG = {
  METADATA: 'SF_EC_METADATA',
  PICKLIST: 'SF_EC_PICKLIST',
};

export const CREATE_FLOW_TYPE = {
  NEW_SYSTEM: 'new',
  EXISTING_SYSTEM: 'existing',
};

export const SF_ENDPOINTS = [
  {
    NAME: 'SF-DC2-prod',
    URL: 'https://api2.successfactors.eu/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC2-preview',
    URL: 'https://api2preview.sapsf.eu/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC2-salesdemo',
    URL: 'https://apisalesdemo2.successfactors.eu/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC4-prod',
    URL: 'https://api4.successfactors.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC4-preview',
    URL: 'https://api4preview.sapsf.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC4-salesdemo',
    URL: 'https://apisalesdemo4.successfactors.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC5-prod',
    URL: 'https://api5.successfactors.eu/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC8-prod',
    URL: 'https://api8.successfactors.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC8-preview',
    URL: 'https://api8preview.sapsf.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC8-salesdemo',
    URL: 'https://apisalesdemo8.successfactors.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC10-prod',
    URL: 'https://api10.successfactors.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC10-preview',
    URL: 'https://api10preview.sapsf.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC12-prod',
    URL: 'https://api012.successfactors.eu/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC12-preview',
    URL: 'https://api12preview.sapsf.eu/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC12-rot',
    URL: 'https://apirot.successfactors.eu/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC15-prod',
    URL: 'https://api15.sapsf.cn/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC15-preview',
    URL: 'https://api15preview.sapsf.cn/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC16-prod',
    URL: 'https://api16.sapsf.cn/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC60-prod',
    URL: 'https://api17.sapsf.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC60-preview',
    URL: 'https://api17preview.sapsf.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC18-prod',
    URL: 'https://api18.sapsf.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC18-preview',
    URL: 'https://api18preview.sapsf.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC62-prod',
    URL: 'https://api19.sapsf.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC62-preview',
    URL: 'https://api19preview.sapsf.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC22-prod',
    URL: 'https://api22.sapsf.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC22-preview',
    URL: 'https://api22preview.sapsf.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC23-prod',
    URL: 'https://api23.sapsf.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC23-preview',
    URL: 'https://api23preview.sapsf.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC41-prod',
    URL: 'https://api41.sapsf.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC41-preview',
    URL: 'https://api41preview.sapsf.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC42-prod',
    URL: 'https://api42.sapsf.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC42-preview',
    URL: 'https://api42preview.sapsf.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC44-prod',
    URL: 'https://api44.sapsf.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC44-preview',
    URL: 'https://api44preview.sapsf.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC47-prod',
    URL: 'https://api47.sapsf.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC47-preview',
    URL: 'https://api47preview.sapsf.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC50-prod',
    URL: 'https://api50.sapsf.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC50-preview',
    URL: 'https://api50preview.sapsf.com/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC55-prod',
    URL: 'https://api55.sapsf.eu/odata/v2/$metadata',
  },
  {
    NAME: 'SF-DC55-preview',
    URL: 'https://api55preview.sapsf.eu/odata/v2/$metadata',
  },
];

export const FILTER_TOGGLES = {
  ALL: 'allNodes',
  SELECTED: 'selectedNodes',
  UNSELECTED: 'unselectedNodes',
};

export const API_ERROR_TYPES = {
  JWT: 'jwt_error',
  UNAUTHORIZED: 'authentication_error',
  BILLING_PLAN: 'no_billing_plan',
  INCORRECT_PAYLOAD: 'incorrect_payload',
  VALIDATION_ERROR: 'validation_error',
};

export const PERMISSION_CODES = {
  READ: 'read_dataflow_connection',
  UPDATE: 'update_dataflow_connection',
  CREATE: 'create_dataflow_connection',
  DELETE: 'delete_dataflow_connection',
  MANAGE_SYSTEM: 'dataflow_manage_system',
  MANAGE_CONNECTION: 'dataflow_manage_connection',
  CREATE_QUERY: 'dataflow_create_query',
  CREATE_BOOKMARK: 'dataflow_create_bookmark',
  EDIT_BOOKMARK: 'dataflow_edit_bookmark',
  DELETE_BOOKMARK: 'dataflow_delete_bookmark',
  CREATE_COLLECTION: 'dataflow_create_collection',
  EDIT_COLLECTION: 'dataflow_edit_collection',
  DELETE_COLLECTION: 'dataflow_delete_collection',
};

export const SECRET_KEYS = {
  CONNECTION_TYPE: {
    KEY: 'CONNECTION_TYPE',
    LABEL: 'SuccessFactors Connection Type',
  },
  SERVICE_ENDPOINT: {
    KEY: 'SERVICE_ENDPOINT',
    LABEL: 'OData Service Metadata Endpoint',
  },
  SF_EC_COMPANY_ID: {
    KEY: 'SF_EC_COMPANY_ID',
    LABEL: 'SuccessFactors Company ID',
  },
  USERNAME: {
    KEY: 'USERNAME',
    LABEL: 'Username',
  },
  PASSWORD: {
    KEY: 'PASSWORD',
    LABEL: 'Password',
  },
  ODATA_VERSION: {
    KEY: 'ODATA_VERSION',
    LABEL: 'OData Version',
  },
  API_KEY: {
    KEY: 'API_KEY',
    LABEL: 'OAuth API Key',
  },
  OAUTH_CERTIFICATE: {
    KEY: 'OAUTH_CERTIFICATE',
    LABEL: 'X.509 Certificate',
  },
  OAUTH_ENDPOINT: {
    KEY: 'OAUTH_ENDPOINT',
    LABEL: 'OAuth Endpoint',
  },
  OAUTH_SF_EC_COMPANY_ID: {
    KEY: 'OAUTH_SF_EC_COMPANY_ID',
    LABEL: 'SuccessFactors Company ID',
  },
  OAUTH_EMAIL_KEY: {
    KEY: 'OAUTH_EMAIL_KEY',
    LABEL: 'OAuth Email Key',
  },
  OAUTH_TECHNICAL_USER_ID: {
    KEY: 'OAUTH_TECHNICAL_USER_ID',
    LABEL: 'OAuth Technical User ID',
  },
};

export const SECRET_TYPES = {
  SECRET: 'secret',
  TEXT: 'text',
};

export const COOKIES = {
  BASIC_AUTH_TOKEN: 'basicToken',
  SSO_AUTH_TOKEN: 'ssoToken',
};

export const PING_TEST = {
  NEUTRAL: 'NEUTRAL',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

export const CANVAS = {
  MAX_NODE_COUNT: 25,
  SPAWN_OFFSET_X: 200,
  SPAWN_OFFSET_Y: 200,
};

export const EMAIL_FORMATS = {
  SUPPORT: {
    subject: 'Need assistance with INTEGRTR.dataflow',
    getBody: (user, tenant) =>
      `Hello,%0A%0AI need some assistance with INTEGRTR.dataflow. Following are my user details:%0A%0AUser: ${
        user?.first_name ?? ''
      } ${user?.last_name ?? ''}%0AEmail ID: ${user?.email ?? ''}%0ATenant Name: ${
        tenant?.name ?? ''
      }%0ATenant ID: ${tenant?.tenant_id ?? ''}%0A%0A`,
  },
  ERROR: {
    subject: 'Unable to access INTEGRTR.dataflow',
    getBody: (user, tenant) =>
      `Hello,%0A%0AI am unable to access INTEGRTR.dataflow. Following are my user details:%0A%0AUser: ${
        user?.first_name ?? ''
      } ${user?.last_name ?? ''}%0AEmail ID: ${user?.email ?? ''}%0ATenant Name: ${
        tenant?.name ?? ''
      }%0ATenant ID: ${tenant?.tenant_id ?? ''}%0A%0A`,
  },
  INQUIRY: {
    subject: 'Inquiry regarding the application usage quota',
    getBody: (user, tenant) =>
      `Hello,%0A%0AI want to inquire about the usage quota for INTEGRTR.dataflow. Following are my user details:%0A%0AUser: ${
        user?.first_name ?? ''
      } ${user?.last_name ?? ''}%0AEmail ID: ${user?.email ?? ''}%0ATenant Name: ${
        tenant?.name ?? ''
      }%0ATenant ID: ${tenant?.tenant_id ?? ''}%0A%0A`,
  },
  BUG: {
    subject: 'INTEGRTR.Dataflow | Found an Issue!',
    getBody: (user, tenant) =>
      `%0A%0A%0A-----------------------------------------------%0AUser: ${user?.first_name ?? ''} ${
        user?.last_name ?? ''
      } (${user?.email ?? ''})
      %0ATenant: ${tenant?.name ?? ''} (${
        tenant?.tenant_id ?? ''
      })%0A-----------------------------------------------`,
  },
  FEEDBACK: {
    subject: 'INTEGRTR.Dataflow | Application Feedback',
    getBody: (user, tenant) =>
      `%0A%0A%0A-----------------------------------------------%0AUser: ${user?.first_name ?? ''} ${
        user?.last_name ?? ''
      } (${user?.email ?? ''})
      %0ATenant: ${tenant?.name ?? ''} (${
        tenant?.tenant_id ?? ''
      })%0A-----------------------------------------------`,
  },
  UPGRADE: {
    subject: 'INTEGRTR.Dataflow | Plan upgrade inquiry',
    getBody: (user, tenant) =>
      `(add your query)%0A%0A%0A-----------------------------------------------%0AUser: ${
        user?.first_name ?? ''
      } ${user?.last_name ?? ''} (${user?.email ?? ''})
      %0ATenant: ${tenant?.name ?? ''} (${
        tenant?.tenant_id ?? ''
      })%0A-----------------------------------------------`,
  },
  BILLING_PLAN: {
    subject: 'INTEGRTR.Dataflow | Billing Plan for Tenant',
    getBody: (user, tenant) =>
      `(add your query)%0A%0A%0A-----------------------------------------------%0AUser: ${
        user?.first_name ?? ''
      } ${user?.last_name ?? ''} (${user?.email ?? ''})
        %0ATenant: ${tenant?.name ?? ''} (${
        tenant?.tenant_id ?? ''
      })%0A-----------------------------------------------`,
  },
};

export const CONNECTION_TYPES = {
  BASIC: {
    KEY: 'basic',
    LABEL: 'Basic Auth',
  },
  OAUTH: {
    KEY: 'oauth',
    LABEL: 'OAuth',
  },
};

export const EMAIL_KEY_TYPES = {
  USERNAME: {
    KEY: 'username',
    LABEL: 'Username',
  },
  EMAIL: {
    KEY: 'email',
    LABEL: 'Email',
  },
};

export const DUMMY_PASSWORD = `_INT_${nanoid(5)}_INT_`;

export const DRAWER_WIDTH = 38;

/**
 * Dates before this date are incremented by 1 day by exceljs when reading from excel file
 */
export const EXCEL_CUTOFF_DATE = new Date('1900-03-01');

export const APPLICATIONS_LINKED_TO_SYSTEM = {
  dataflowConnections: { KEY: 'dataflowConnections', NAME: 'Dataflow' },
  deploymentTargets: { KEY: 'deploymentTargets', NAME: 'Deployment Target' },
  mapperProjects: { KEY: 'mapperProjects', NAME: 'Mapper' },
  mdgenTemplates: { KEY: 'mdgenTemplates', NAME: 'MDGen Templates' },
  templates: { KEY: 'templates', NAME: 'HR Data Generator' },
  userSystemMiddlewares: { KEY: 'userSystemMiddlewares', NAME: 'User System Middlewares' },
  systemComparisons: { KEY: 'systemComparisons', NAME: 'Systems Connection' },
};

export const ERROR_CODES = {
  FAILED__BILLING_ERROR: 'FAILED__BILLING_ERROR',
  FAILED__PING_TEST: 'FAILED__PING_TEST',
  FAILED__MISSING_SYSTEM_SECRETS: 'FAILED__MISSING_SYSTEM_SECRETS',
  FAILED__MISSING_CONNECTION_TYPE: 'FAILED__MISSING_CONNECTION_TYPE',
};

export const LOCAL_STORAGE_KEYS = {
  QUERY_SETTINGS: {
    VIEW_PICKLIST_VALUES: '__integrtr_dataflow_view_picklist_values_during_fetch',
    PAGE_SIZE: '__integrtr_dataflow_page_size',
  },
  EXPORT_FLOW_PERSONALISATIONS: {
    VIEW_PICKLIST_VALUES: '__integrtr_dataflow_view_picklist_values_during_export',
    SHEET_NAMING_CONVENTION: '__intergtr_dataflow_sheet_naming_convention',
    RANDOMIZATION: '__integrtr_dataflow_randomization',
    ANONYMIZATION: '__integrtr_dataflow_anonymization',
  },
};

export const HELP_PAGES = {
  OAUTH_TROUBLESHOOTING_GUIDE:
    '/integrtrdataflow--9fb8f7c2-d762-4f71-854a-03015dae2bfb/connectivity-related-issue--d793abb8-cb5c-4161-8886-594387d52230',
  DATAFLOW_HOME: '/dataflow--9fb8f7c2-d762-4f71-854a-03015dae2bfb',
};

export const PAGE_SIZE_DEFAULTS = {
  DEFAULT: 100,
  UPPER_LIMIT: 1000,
  LOWER_LIMIT: 10,
};
