/**
 * @file
 *
 * This file contains the svg component for the coming soon image
 */

import * as React from 'react';

const DfConsumerGetStartedImage = (props) => (
  <svg viewBox="0 0 771.79 486.4" {...props}>
    <defs>
      <style>
        {
          '\n      .cls-1, .cls-7 {\n        fill: none;\n      }\n\n      .cls-2 {\n        fill: #e5e5e5;\n      }\n\n      .cls-3 {\n        clip-path: url(#clip-path);\n      }\n\n      .cls-4 {\n        fill: #38495c;\n      }\n\n      .cls-5 {\n        fill: #fff;\n      }\n\n      .cls-6 {\n        fill: #d3d3d3;\n        stroke: #38495c;\n      }\n\n      .cls-6, .cls-7 {\n        stroke-miterlimit: 10;\n      }\n\n      .cls-7 {\n        stroke: #fff;\n      }\n    '
        }
      </style>
      <clipPath id="clip-path" transform="translate(0.05 -0.9)">
        <rect className="cls-1" x={0.35} width={770.92} height={36.61} />
      </clipPath>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="Layer_2-2" data-name="Layer 2">
          <path
            className="cls-2"
            d="M757.91,484.66H13.54A13.54,13.54,0,0,1,0,471.12V14.44A13.54,13.54,0,0,1,13.54.9H757.91a13.54,13.54,0,0,1,13.54,13.54V471.12A13.54,13.54,0,0,1,757.91,484.66Z"
            transform="translate(0.05 -0.9)"
          />
          <g className="cls-3">
            <path
              className="cls-4"
              d="M758.23,61.45H13.46A13.52,13.52,0,0,1,0,47.91V14.67A13.52,13.52,0,0,1,13.46,1.13H758.23a13.52,13.52,0,0,1,13.51,13.54V47.91A13.52,13.52,0,0,1,758.23,61.45Z"
              transform="translate(0.05 -0.9)"
            />
          </g>
          <circle className="cls-5" cx={71.13} cy={16.84} r={5.9} />
          <circle className="cls-5" cx={48.83} cy={16.82} r={5.9} />
          <circle className="cls-5" cx={25.9} cy={16.56} r={5.9} />
          <rect className="cls-5" x={548.36} y={179.9} width={205.43} height={118.24} />
          <path
            className="cls-2"
            d="M657.61,212.33H574.5a6.38,6.38,0,0,1-6.38-6.38h0a6.38,6.38,0,0,1,6.38-6.38h83.11A6.38,6.38,0,0,1,664,206h0A6.38,6.38,0,0,1,657.61,212.33Z"
            transform="translate(0.05 -0.9)"
          />
          <rect className="cls-5" x={548.21} y={309.2} width={205.43} height={118.24} />
          <path
            className="cls-2"
            d="M657.45,341.62H574.34a6.38,6.38,0,0,1-6.38-6.38h0a6.38,6.38,0,0,1,6.38-6.38h83.11a6.38,6.38,0,0,1,6.38,6.38h0A6.38,6.38,0,0,1,657.45,341.62Z"
            transform="translate(0.05 -0.9)"
          />
          <rect className="cls-5" x={547.25} y={50.21} width={205.43} height={118.24} />
          <path
            className="cls-6"
            d="M656.5,82.63H573.39A6.38,6.38,0,0,1,567,76.25h0a6.38,6.38,0,0,1,6.38-6.38H656.5a6.38,6.38,0,0,1,6.38,6.38h0A6.38,6.38,0,0,1,656.5,82.63Z"
            transform="translate(0.05 -0.9)"
          />
          <polygon
            className="cls-4"
            points="663.83 89.47 670.77 91.6 665.46 96.54 660.14 101.48 658.52 94.41 656.9 87.34 663.83 89.47"
          />
          <path
            className="cls-4"
            d="M527.72,109.1H507.86a7.28,7.28,0,0,1-7.28-7.28V82.35a7.28,7.28,0,0,1,7.28-7.28h19.86A7.28,7.28,0,0,1,535,82.35v19.47A7.28,7.28,0,0,1,527.72,109.1Z"
            transform="translate(0.05 -0.9)"
          />
          <path
            className="cls-7"
            d="M523.21,99.07H511.79a3.26,3.26,0,0,1-3.26-3.26V88.33a3.26,3.26,0,0,1,3.26-3.26h11.42a3.26,3.26,0,0,1,3.26,3.26v7.48A3.26,3.26,0,0,1,523.21,99.07Z"
            transform="translate(0.05 -0.9)"
          />
          <rect className="cls-5" x={517.58} y={84.22} width={5.34} height={14.04} />
          <rect className="cls-5" x={548.52} y={437.83} width={205.43} height={48.57} />
          <path
            className="cls-2"
            d="M657.77,470.25H574.66a6.38,6.38,0,0,1-6.38-6.38h0a6.38,6.38,0,0,1,6.38-6.38h83.11a6.38,6.38,0,0,1,6.38,6.38h0A6.38,6.38,0,0,1,657.77,470.25Z"
            transform="translate(0.05 -0.9)"
          />
          <polygon
            className="cls-5"
            points="670.77 91.6 663.12 93.83 660.14 101.48 666.25 97.04 670.77 91.6"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default DfConsumerGetStartedImage;
